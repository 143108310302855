@font-face {
  font-family: OpenSans-SemiBold;
  src: url(./fonts/OpenSans-SemiBold.ttf);
}
@font-face {
  font-family: OpenSans-Light;
  src: url(./fonts/OpenSans-Light.ttf);
}
@font-face {
  font-family: OpenSans-Bold;
  src: url(./fonts/OpenSans-Bold.ttf);
}
body {
  width: 100vw;
  height: 100vh;
  margin: auto;
  font-family: OpenSans-Light;
  overflow-x: hidden;
  font-size: 140%;
  color: rgb(15, 15, 15);
}
a{
  text-decoration: none;
}
#videoContainer {
  position: absolute;
  height: calc(100vh - 250px);
  margin-top: -250px;
}
#video {
  position: relative;
  width: 100%;
}

.buttonComp {
  padding: 15px;
  padding-left: 25px;
  padding-right: 25px;
  background: white;
  border: solid 1px black;
  color: black;
  font-family: OpenSans-SemiBold;
  letter-spacing: 5px;
  font-size: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  cursor: pointer;
  transition-duration: 0.3s;
}
.buttonComp:hover {
  background: black;
  color: white;
}

.sectionTitle{
  font-family: OpenSans-Bold;
  letter-spacing: 15px;
  font-size: larger;
  line-height: 50px;
  width: 100%;
  margin: auto;
  text-align: center;
  padding-top: 60px;
  padding-bottom: 30px;
}

.header {
  display: flex;
  justify-content: space-between;
  width: calc(100vw - 22px);
  height: 150px;
  margin: auto;
  border-bottom: solid 1px white;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
  top: 0;
  position: fixed;
  background: white;
  /*border: solid 1px brown;*/
  z-index: 100;
}
.logo {
  /*border: solid 1px red;*/
  line-height: 160px;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 50%;
  padding-top: 10px;
  cursor: pointer;
}
.logo img {

  max-height: 115px;
  max-width: 100%;
  vertical-align: middle;
  /*border: solid 1px blue;*/
}
.header .cartIcon, .header .menu  {
  font-size: 140%;
  /*border: solid 1px red;*/
  line-height: 80px;
  min-width: 50px;
  text-align: center;
  
}

.header .cartIcon .img1{
  border-radius: 50%;
  color: black;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  margin: auto 3px;
  width: 30px;
  font-size: 15px;
  text-align: center;
  position: absolute;
  margin-left: -60px;
  margin-top: 30px;
}
.header .cartIcon .img2{ 
  border-radius: 50%;
  color: black;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  margin: auto 3px;
  width: 30px;
  font-size: 15px;
  text-align: center;
  position: absolute;
  margin-top: 30px;
  margin-left: -20px;
}

.header .cartIcon .theIcon, .header .menu .theIcon  { 
  /*border: solid 1px blue;*/
  cursor: pointer;
}


.slide-container {
  width: 100vw;
  overflow: hidden;
}
.slide-container .each-slide div {
  margin-top: 150px;
  height: calc(100vh - 250px);
}
.slide-container .slogan {
  color: white;
  text-shadow: -3px 5px 14px #595959;
  border-radius: 5px;
  width: 80%;
  padding-left: 10%;
  padding-right: 10%;
  position: absolute;
  margin: auto;
  text-align: center;
  height: 200px;
  z-index: 50;
  font-size: 110%;
  margin-top: -50vh;
  letter-spacing: 7px;
}
.slide-container .slogan h1{
  
  text-shadow: 0px 0px 8px rgba(18,18,18,0.8);
}

.homepageText{
  width: 100%;
  background-color: white;
  padding-top: 60px;
}

.homePage .homeText{
  width: 90%;
  max-width: 1200px;
  margin: auto;
  background: white;
}
.homePage .homeText .titles{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
  margin-top: 100px;
  font-family: OpenSans-Bold;
  letter-spacing: 15px;
  font-size: larger;
}

.homePage .homeText .titles .red span{
  border-bottom: 5px #9c1b30 solid;
  line-height: 50px;
}
.homePage .homeText .titles .blue span{
  border-bottom: 5px #004e7d solid;
  line-height: 50px;
}
.homePage .homeText .titles .yellow span{
  border-bottom: 5px #ecab20 solid;
  line-height: 50px;
  
}

.homePage .homeText .textes{
  margin-top: 60px;
  text-align: center;
  text-align-last: center;
  font-size: 105%;
}



.servicesPage {
  background: #f3f3f3;
  width: 100vw;
  margin-top: 90px;
  padding-top: 30px;
  padding-bottom: 120px;
}
.servicesPage .sectionTextes{
  width: 90%;
  max-width: 1200px;
  margin: auto;
  text-align: center;
  text-align-last: center;
  font-size: 105%;
}
.servicesPage .sectionNumbers{
  margin-top: 90px;
}
.servicesPage .sectionBlocks{
  width: 90%;
  max-width: 1200px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 30px;
  margin-top: 90px;

}
.servicesPage .sectionBlocks .serviceBlock {
  overflow: hidden;
  position: relative;
  padding-top: 100%;
}
.servicesPage .sectionBlocks .serviceBlock .serviceBlockBack{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.servicesPage .sectionBlocks .serviceBlock .serviceBlocktext{
  width: 100%;
  height: 100%;
  text-align: center;
  position: absolute;
  padding-top: 50%;
  text-shadow: 0px 0px 19px rgba(18,18,18,0.8);
  color: white;
  font-size: 95%;
  font-family: OpenSans-SemiBold;
  letter-spacing: 5px;
  background-color: rgb(39, 39, 39, 0.6);
  transition-duration: 0.2s;
  cursor: pointer;
}
.servicesPage .sectionBlocks .serviceBlock .serviceBlocktext:hover{
  font-size: 110%;
}
.servicePop {
  width: 100vw;
  height: 100vh;
  position: absolute;
  padding: 0;
  background: black;
}


.realisationsPage{
  width: 100vw;
  padding-top: 30px;
  padding-bottom: 90px;

}
.realisationsPage .theGrid{
  width: 90%;
  max-width: 1200px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 30px;
  margin-top: 30px;
}
.realisationsPage .theGrid .realisation{
  overflow: hidden;
  position: relative;
  padding-top: 100%;
  cursor: pointer;
}
.realisationsPage .theGrid .realisation:hover{
  -webkit-box-shadow: inset 0px 0px 150px 5px rgba(0,0,0,0.25); 
  box-shadow: inset 0px 0px 150px 5px rgba(0,0,0,0.25);
}





.environnementPage{
  width: 100vw;
  padding-top: 30px;
  padding-bottom: 30px;
  background-image: url('./components/imgs/back.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  text-align: center;
}
.environnementPage .evnContainer{
  background: white;
  width: 90%;
  max-width: 1200px;
  margin: auto;
  min-height: 300px;
  margin-top: 90px;
  margin-bottom: 90px;
  padding-top: 30px;
  padding-bottom: 90px;
}
.environnementPage .evnContainer .certif{
  font-style: italic;
  padding-top: 30px;
  width: 70%;
  margin: auto;
}
.environnementPage .evnContainer .certif .title{
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
}
.environnementPage .evnContainer .saviezvous{
  padding-top: 60px;
  width: 90%;
  margin: auto;
}
.environnementPage .evnContainer .saviezvous .title {
  font-family: OpenSans-SemiBold;
  letter-spacing: 3px;
}
.environnementPage .evnContainer .saviezvous .facts{
  display: grid;
  grid-template-columns: 2fr 3fr 2fr;
  padding-top: 20px;
}
.environnementPage .evnContainer .saviezvous .facts .fact{
  min-height: 200px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
}
.environnementPage .evnContainer .saviezvous .facts .center{
  border-left: solid 1px black;
  border-right: solid 1px black;
}
.environnementPage .evnContainer .saviezvous .facts .fact .img img{
  height: 75px;
}
.environnementPage .evnContainer .saviezvous .facts .fact .text{
  margin-top: -10px;
  padding-left: 25px;
  padding-right: 25px;
}
.certif{
text-align: center;
}




.contactPage{
  width: 100vw;
  padding-top: 40px;
  padding-bottom: 90px;
}
.contactPage .locations{
  padding-top: 30px;
  width: 90%;
  max-width: 1350px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: '1fr 1fr 1fr' '1.5fr 1.5fr';
  text-align: center;
}
.contactPage .locations .location{
  padding: 10px;
}
.contactPage .locations .location .title{
  font-family: OpenSans-SemiBold;
  letter-spacing: 3px;
  margin-bottom: 10px;
}
.contactPage .locations .location .tel{
  font-weight: bold;
}


.contactPage .suppinfo{
  padding-top: 30px;
  width: 90%;
  max-width: 1350px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
}
.contactPage .suppinfo .location{
  padding: 10px;
}
.contactPage .suppinfo .location .title{
  font-family: OpenSans-SemiBold;
  letter-spacing: 3px;
  margin-bottom: 10px;
}
.contactPage .suppinfo .location .tel{
  font-weight: bold;
}



.footer{
  width: 100vw;
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: center;
  background: #f3f3f3;
  font-size: 80%;
}




.callToAction{
  width: 90%;
  max-width: 1200px;
  margin: auto;
  text-align: center;
  margin-top: 30px;
}



.countup {
  width: 90%;
  max-width: 1200px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto;
  text-align: center;
}
.countup .counter{
  font-size: 200%;
}
.countup .description{
  font-size: 75%;
  font-family: OpenSans-SemiBold;
  letter-spacing: 5px;
}
.countup .red{
  color: #9c1b30;
}
.countup .blue{
  color: #004e7d;
}
.countup .yellow{
  color: #ecab20;
}




.chatbubble {
  position: fixed;
  bottom: 30px;
  right: 30px;
}
.chatbubble .chaticon{
  width: 50px;
  height: 50px;
  cursor: pointer;
  border: solid 3px #1b1b1b;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  background: #1b1b1b;
  font-weight: bold;
  color: white;
  transition-duration: 0.3s;
  float: right;
  margin-right: 10px;
  font-size: 60%;
}
.chaticon img{
  width: 30px;
  height: 30px;
  margin-top: 10px;
}

.chatsection{
  width: 350px;
  height: 400px;
  background: #1b1b1b;
  border-radius: 5px;
  padding: 3px;
  margin-bottom: -50px;
}
.chatsection .top{
  line-height: 45px;
  padding: 5px;
  color: white;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 70%;
}
.chatsection .msgs{
  height: calc(340px - 21px);
  width: calc(100% - 10px);
  padding: 5px;
  background: white;
}
.chatsection .msgs .msg{
  background: rgb(240, 240, 240);
  padding: 10px;
  border: solid 1px rgb(216, 216, 216);
  border-radius: 5px;
  max-width: 80%;
}
.chatsection .top .close{
  width: 20px;
  height: 20px;
  line-height: 20px;
  float: right;
  margin-right: 10px;
  cursor: pointer;
}
.chatsection .top .close:hover{
  background: #de003b;
}

.chatsection form {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 15px;
}
.chatsection input {
  width: calc(100% - 20px);
  border: none;
  border-bottom: solid 1px rgb(211, 211, 211);
  font-family: OpenSans-Light;
  font-size: 80%;

}
.chatsection textarea {
  width: calc(100% - 20px);
  border: none;
  border-bottom: solid 1px rgb(211, 211, 211);
  font-family: OpenSans-Light;
  font-size: 80%;
  height: 100px;
}
.chatsection input:focus, .chatsection textarea:focus{
  outline: none;
  border-bottom: black 1px solid;
}


.chatsection .contactSend{
  padding: 10px;
  padding-left: 25px;
  padding-right: 25px;
  background: white;
  border: solid 1px black;
  color: black;
  font-family: OpenSans-SemiBold;
  letter-spacing: 5px;
  font-size: 70%;
  cursor: pointer;
  transition-duration: 0.3s;
  margin-top: 20px;
}
.chatsection .contactSend:hover{
  background: black;
  color: white;
}





.soumission {
  position: fixed;
  bottom: 30px;
  left: 30px;
}
.soumission .soumissionButton {
  min-width: 200px;
  cursor: pointer;
  background: #1b1b1b;
  color: white;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  border-radius: 10px;
  font-weight: bold;
  font-family: OpenSans-SemiBold;
  letter-spacing: 5px;
  font-size: 80%;
}








/* THE SLIDING MENU */
.sideMenuContainer {
  width: 100%;
  height: calc(100vh -150px);
  overflow: scroll;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.sideMenuContainer .menuContainer {
  /*border: solid 1px red;*/
  background-color: #4d4d4e;
  width: 350px;
  height: calc(100vh - 151px);
  border-right: 1px silver solid;
  color: white;
  font-weight: bold;
}
.sideMenuContainer .menuContainer .noItem {
  width: 100%;
  text-align: center;
  line-height: 60px;
  border-bottom: solid 1px white;
  transition: 0.3s;
  cursor: pointer;
  background-color: #4d4d4f;
  font-family: OpenSans-SemiBold;
  letter-spacing: 5px;
  font-size: 80%;
}
.sideMenuContainer .menuContainer .noItem:hover {
  background: white;
  color: #002B43;
  border-bottom: #4d4d4f 1px solid;
}
.sideMenuContainer .menuContainer .subItem {
  background: white;
  color: rgba(0, 43, 67, 1);;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
  display: none;
}
.sideMenuContainer .menuContainer .subItem:hover { 
  background: #bebebe;
}
.sideMenuContainer .menuContainer .arrow {
  float: right;
  margin-right: 20px;
  font-size: 200%;

}


.sideMenuContainer .shadowOverlay {
  /*border: solid 1px red;*/
  /*background-color: rgba(0, 0, 0, 0.4);*/
  width: calc(100% - 540px);
  height: calc(100vh - 200px);
  cursor: pointer;
}

.sideslide {
  position: fixed;
  width: 100%;
  height: calc(100vh - 150px);

  margin-top: 150px;

}



/* Dark background */
.darkBackground {
  width: 100%;
  height: calc(100vh - 150px);
  overflow: hidden;
  /*border: solid 1px purple;*/
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
}


.soumissionform, .servicepop{
  width: calc(100vw - 100px);
  height: 100vh;
  padding: 50px;
  padding-top: 50px;
}
.iconClose{
  font-size: 150%;
  cursor: pointer;
  display: inline-block;
}
.poplogo {
  margin: auto;
  text-align: center;
}

.servicepoptext{
  width: 80%;
  margin: auto;
  padding-bottom: 60px;
}
.Collapsible__trigger {
  font-family: OpenSans-SemiBold;
  letter-spacing: 5px;
  font-size: 110%;
  cursor: pointer;
}
.collapseServices{
  float: right;
}
.Collapsible {
  border-bottom: 2px black solid;
  width: 100%;
  padding-bottom: 10px;
  padding-top: 30px;
}


.reapage{
  width: calc(100vw - 100px);
  min-height: 100vh;
  padding: 50px;
  margin: auto;
  padding-bottom: 60px;
  background: #4d4d4f;
  color: white;
}
.reapage .reaslide{
  width: 60vw;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  padding-top: 100px;
}
.reapage .reaslide .middle{
  padding-top: 100%;
  position: relative;
}
.reapage .reaslide .left,.reapage .reaslide .right {
  font-size: 300%;
  position: relative;
  
}
.reapage .reaslide .left section,.reapage .reaslide .right section {
  position: absolute;
  top:50%;
  text-align: center;
  width: 100%;
  cursor: pointer;
}
.reapage .content{
  width: 60vw;
  margin: auto;
  padding-top: 50px;
  text-align: center;
}
.reapage .content .title{
  font-family: OpenSans-SemiBold;
  letter-spacing: 5px;
  font-size: 110%;
}






.contactform {
  width: 100%;
  max-width: 1375px;
  margin: auto;
  padding-bottom: 50px;
}
.contactform form {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 15px;
}
.contactform input {
  width: calc(100% - 20px);
  border: none;
  border-bottom: solid 1px rgb(211, 211, 211);
  padding: 10px;
  font-size: 120%;
  font-family: OpenSans-Light;

}
.contactform .verif {
  width: 60% !important;
  margin: auto;
  text-align: center;
}
.contactform textarea {
  width: calc(100% - 20px);
  border: none;
  border-bottom: solid 1px rgb(211, 211, 211);
  padding: 10px;
  font-size: 120%;
  height: 150px;
  font-family: OpenSans-Light;
}
.contactform input:focus, .contactform textarea:focus{
  outline: none;
  border-bottom: black 1px solid;
}


.contactform .contactSend{
  padding: 15px;
  padding-left: 25px;
  padding-right: 25px;
  background: white;
  border: solid 1px black;
  color: black;
  font-family: OpenSans-SemiBold;
  letter-spacing: 5px;
  font-size: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  cursor: pointer;
  transition-duration: 0.3s;
}
.contactform .contactSend:hover{
  background: black;
  color: white;
}


.done {
  background: white;
  font-size: 150%;
  width: 100vw;
  height: 100vh;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  overflow: hidden;
}
.done .done_text{
  border: solid 1px silver;
  background: #c8e9cd;
  color: green;
  padding: 25px;
  width: 250px;
  margin: auto;
  border-radius: 5px;
  bottom: 50;
  left: 50;
}





/* SMALL SCREENS */
@media screen and (max-width: 900px) {
  .header {
    min-height: 80px;
  }
  .logo {
    min-height: 80px;
  }
  #video {
    height: 100vh;
    background: black;
  }
  .homePage .homeText{
    margin-top: -60px;
  }
  .homePage .homeText .titles{
    grid-template-columns: 1fr;
    letter-spacing: 5px;
  }
  .homePage .homeText .titles .red span, .homePage .homeText .titles .blue span, .homePage .homeText .titles .yellow span{
    display: block;
    width: 80%;
    margin: auto;
  }
  .slide-container .slogan {
    font-size: 50%;
    margin-top: -50vh;
    z-index: 90;
  }

  .soumission {
    border: solid 1px transparent;
    border-radius: 10px;
    bottom: 150px;
    left: -145px;
    transform: rotate(90deg);
  }
  .soumission .soumissionButton {
    transform: scale(0.8);
    -ms-transform: scale(0.8);
  }
  .chatbubble {
    z-index: 100;
  }
  .chatsection{
    margin-right: -30px;
    width: 100vw;
  }

  .sectionTitle{
    letter-spacing: 5px;
  }

  .countup {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto auto auto;
    grid-row-gap: 20px;
  }
  .countup .red{
    -webkit-order: 1;
    order: 1;
    font-size: 280%;
  }
  .countup .blue{
    -webkit-order: 3;
    order: 3;
    font-size: 280%;
  }
  .countup .yellow{
    -webkit-order: 5;
    order: 5;
    font-size: 280%;
  }
  .countup .desred{
    -webkit-order: 2;
    order: 2;
  }
  .countup .desblue{
    -webkit-order: 4;
    order: 4;
  }
  .countup .desyellow{
    -webkit-order: 6;
    order: 6;
  }

  .servicesPage .sectionBlocks{
    grid-template-columns: 1fr;
    grid-row-gap: 30px;
  }


  .realisationsPage .theGrid{
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .environnementPage .evnContainer .saviezvous .facts{
    grid-template-columns: 1fr;
  }
  .environnementPage .evnContainer .saviezvous .facts .center{
    border: none;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .contactPage .locations{
    grid-template-columns: 1fr;
  }

  .reapage{
  }
  .reapage .reaslide{
  }

}